<template>
  <v-dialog v-model="mostrar" persistent width="750">
    <v-card :loading="loading">
      <v-toolbar dense flat color="primary" dark>
        <v-toolbar-title>{{
          pagina ? "Modificar página" : "Agregar página"
        }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="pb-0 px-4 pt-3">
        <v-row dense>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="nombre"
              label="Nombre"
              filled
              counter="20"
              :error-messages="errors.nombre"
              @input="validarNombre()"
              @blur="validarNombre()"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-select
              v-model="tipo"
              :items="tipos"
              label="Tipo de página"
              outlined
              item-text="text"
              item-value="value"
            ></v-select>
          </v-col>
          <v-col v-if="tipo === 'lectura'" cols="12">
            <v-alert v-if="errors.lectura.length" type="error" text dense>{{
              errors.lectura[0]
            }}</v-alert>
            <editor-texto
              ref="editor"
              :texto="lectura"
              contenido="examen"
              :idContenido="$route.params.idExamen"
              @textoModificado="textoLecturaModificado"
            ></editor-texto>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="secondary"
          small
          outlined
          :disabled="loading"
          @click="$emit('cancelar')"
          >Cancelar</v-btn
        >
        <v-btn
          color="primary"
          small
          :disabled="loading || !esValido"
          :loading="loading"
          @click="submit()"
          >Guardar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { crearPagina, modificarPagina } from "./examenes.service";
import { required, maxLength } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";

import editorTexto from "../contenidos/editorTexto.vue";

const validMaxLength = 20;

export default {
  mixins: [validationMixin],

  props: {
    mostrar: { type: Boolean, default: true },
    pagina: { type: Object, default: () => null },
  },

  components: {
    "editor-texto": editorTexto,
  },

  data: () => ({
    loading: false,
    nombre: "",
    lectura: "",
    tipo: "reactivos",
    tipos: [
      {
        text: "Pila de reactivos",
        value: "reactivos",
      },
      {
        text: "Lectura",
        value: "lectura",
      },
    ],
    errors: {
      nombre: "",
      lectura: "",
    },
  }),

  computed: {
    esValido() {
      if (this.errors.nombre.length) return false;
      if (this.errors.lectura.length) return false;
      return true;
    },
  },

  watch: {
    pagina() {
      this.setCurrentData();
    },
  },

  validations: {
    nombre: { required, maxLength: maxLength(validMaxLength) },
    lectura: { required },
  },

  mounted() {
    this.setCurrentData();
  },

  methods: {
    setCurrentData() {
      if (this.pagina) {
        this.nombre = this.pagina.nombre;
        this.tipo = this.pagina.tipo;
        this.lectura = this.pagina.lectura || "";
      }
    },

    textoLecturaModificado(nuevoTexto) {
      this.lectura = nuevoTexto === "<p></p>" ? "" : nuevoTexto;
      this.validarLectura();
    },

    validarFormulario() {
      this.validarNombre();
      this.validarLectura();
    },

    validarNombre() {
      this.$v.nombre.$touch();
      this.errors.nombre = [
        ...(!this.$v.nombre.required ? ["El nombre es requerido"] : []),
        ...(!this.$v.nombre.maxLength
          ? [`El nombre no puede tener más de ${validMaxLength} letras`]
          : []),
      ];
    },

    validarLectura() {
      this.$v.lectura.$touch();
      this.errors.lectura = [
        ...(this.tipo === "lectura" && !this.$v.lectura.required
          ? ["La lectura es requerida para este tipo de página"]
          : []),
      ];
    },

    async submit() {
      this.validarFormulario();
      if (!this.esValido) return;
      this.loading = true;

      try {
        const { idExamen } = this.$route.params;
        const requestData = {
          nombre: this.nombre,
          tipo: this.tipo,
          seccion: this.$route.params.idSeccion,
          ...(this.tipo === "lectura" && { lectura: this.lectura }),
        };
        const serverResponse = this.pagina
          ? await modificarPagina({
              idPagina: this.pagina._id,
              idExamen,
              data: requestData,
            })
          : await crearPagina({ idExamen, data: requestData });
        this.loading = false;

        if (!serverResponse.ok) this.$systemErrorMessage(serverResponse.mensaje);
        else this.$emit("paginaGuardada", serverResponse.pagina);
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>
